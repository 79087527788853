import Typography from "typography"

const theme = {
  baseFontSize: "16px",
  baseLineHeight: "1.5rem",
  headerFontFamily: ["DejaVuSansMonoBold", "monospace"],
  bodyFontFamily: ["DejaVuSansMonoBook", "monospace"],
  bodyColor: "white",
  modularScales: [
    {
      scale: 2.4375,
    },
    {
      scale: "octave",
      maxWidth: "768px",
    },
  ],
  headerGray: 20,
  bodyGray: 20,
  headerWeight: "normal",
  bodyWeight: "normal",
  boldWeight: "bold",
  googleFonts: [],
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
