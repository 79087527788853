import React from 'react'
import { Link } from 'gatsby'

import './layout.css'

import { rhythm /*, scale */ } from '../utils/typography'
class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    const header = (
      <div
        className="row"
        style={{
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link className={`${location.pathname === '/' && 'is-active'}`} to={`/`}>
          jmfurlott
        </Link>
        <span style={{ marginLeft: '1rem' }}>Software Engineer</span>
        <Link
          className={`${location.pathname === '/contact' && 'is-active'}`}
          style={{ float: 'right', marginLeft: '1rem' }}
          to={`/contact`}
        >
          contact
        </Link>
        {/*
           <Link
             className={`${location.pathname === "/links" && "is-active"}`}
             style={{ float: "right", marginLeft: "1rem" }}
             to={`/links`}
           >
            links
           </Link>
          */}
        <Link
          className={`${location.pathname === '/projects' && 'is-active'}`}
          style={{ float: 'right', marginLeft: '1rem' }}
          to={`/projects`}
        >
          projects
        </Link>
        <Link
          className={`${location.pathname === '/freelance' && 'is-active'}`}
          style={{ float: 'right' }}
          to={`/freelance`}
        >
          freelance
        </Link>
      </div>
    )
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(32),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>© {new Date().getFullYear()}</footer>
        <a href="mailto:jmfurlott@gmail.com">jmfurlott@gmail.com</a>
      </div>
    )
  }
}

export default Layout
